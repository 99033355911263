import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { Typography, Hidden } from "@material-ui/core";
import FabButton from "./FabButton";
import clsx from "clsx";
import LanguageChangerButton from "./LanguageChangerButton";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
  middlePadding: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  title: {
    fontSize: `calc(0.8em + 0.5vw)`,
    [theme.breakpoints.up("lg")]: {
      fontSize: `calc(1.5rem + 0.5vw)`,
      color: theme.palette.green,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: `calc(1.2rem + 0.5vw)`,
      color: theme.palette.green,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: `calc(0.9rem + 0.5vw)`,
      color: theme.palette.green,
    },
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    fontSize: `calc(0.80em + 0.5vw)`,
    cursor: "pointer",
  },
  logo: {
    maxWidth: 100,
    marginRight: "10px",
  },
  flagIcon: {
    marginRight: -12,
    color: theme.palette.writing,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const sections = [
  { title: "home", url: "home" },
  { title: "areas", url: "areas" },
  { title: "articles", url: "articles" },
  // { title: "testimonials", url: "testimonials" },
  { title: "contact", url: "contact" },
];

export default function Header() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const onScroll = () => {
    setVisible(document.documentElement.scrollTop > 100);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    // Remove listener on unmount
    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <React.Fragment>
      <Hidden smUp>
        <Toolbar id="top" className={classes.toolbar}>
          <img alt="logo" className={classes.logo} src={logo} />
          <Typography className={classes.title}>
            Av. Nuray Kaya Gürpınar, <em> LL.M. </em>
          </Typography>
        </Toolbar>
        <Link
          className={classes.toolbarLink}
          activeClass="active"
          to="top"
          spy={true}
          smooth={true}
        >
          {visible && <FabButton />}
        </Link>
      </Hidden>
      <Hidden xsDown>
        <Toolbar id="home" className={classes.toolbar}>
          <img alt="logo" className={classes.logo} src={logo} />
          <Typography className={classes.title}>
            Av. Nuray Kaya Gürpınar, <em> LL.M. </em>
          </Typography>
          {/* Temporary Box Here */}
          <Box className={classes.middlePadding} />
          {sections.map((section) => (
            <Link
              className={classes.toolbarLink}
              activeClass="active"
              to={section.url}
              spy={true}
              smooth={true}
              key={section.url}
            >
              {t(section.title)}
            </Link>
          ))}
          <LanguageChangerButton
            className={clsx(classes.flagIcon, classes.toolbarLink)}
          />
        </Toolbar>
        <Link
          className={classes.toolbarLink}
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
        >
          {visible && <FabButton />}
        </Link>
      </Hidden>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};

// props for future reference:
// * activeClass - class applied when element is reached
// * spy - make Link selected when scroll is at its targets position
// * smooth - animate the scrolling

import React, { useState, useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import LanguageChangerButton from "./LanguageChangerButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  langButton: {
    color: "#b8b7ad",
    position: "absolute",
    top: 8,
    left: 8,
    zIndex: 1,
  },
}));

var styles = {
  bmBurgerButton: {
    // position: "fixed",
    position: "absolute",
    width: "36px",
    height: "30px",
    // left: "36px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1em 0",
    fontSize: "3em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    marginLeft: -30,
  },
};

const sections = [
  { title: "home", url: "home" },
  { title: "areas", url: "areas" },
  { title: "articles", url: "articles" },
  // { title: "testimonials", url: "testimonials" },
  { title: "contact_us", url: "contact" },
];

// make a new context
const MyContext = React.createContext(1);

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

// create a button that calls a context function to set a new open state when clicked
const ButtonLink = ({ title }) => {
  const ctx = useContext(MyContext);
  return (
    <Typography variant={"button"} onClick={ctx.toggleMenu}>
      {title}
    </Typography>
  );
};

const MenuComponent = () => {
  const { t } = useTranslation();
  // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
  const ctx = useContext(MyContext);

  const classes = useStyles();

  return (
    <Menu
      styles={styles}
      right
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
      itemListElement="div"
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
    >
      <List>
        {sections.map((section) => (
          <ListItem alignItems="center" key={section.url}>
            <Link
              id="page-wrap"
              activeClass="active"
              to={section.url}
              spy={true}
              smooth={true}
            >
              <ButtonLink title={t(section.title)} />
            </Link>
            <Box style={{ height: 40 }} />
          </ListItem>
        ))}
      </List>
      <LanguageChangerButton className={classes.langButton} />
    </Menu>
  );
};

// default export here
const MobileMenu = () => {
  return (
    <MyProvider>
      <div>
        <MenuComponent />
      </div>
    </MyProvider>
  );
};

export default MobileMenu;

import SplitContent from "./SplitContent";
import MapComponent from "./MapComponent";

export default function AddressSection() {
  return (
    <>
      <SplitContent />
      <MapComponent />
    </>
  );
}

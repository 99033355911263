import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 5,
    paddingTop: 20,
  },
  textStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    fontSize: `calc(1em + 0.5vw)`,
  },
  externalLinks: {
    marginBottom: 10,
  },
  icon: {
    color: theme.palette.green,
  },
}));

export default function Footer() {
  const classes = useStyles();

  function IconHolder(props) {
    const { customLink, IconImplant } = props;
    return (
      <a href={customLink} target="_blank" rel="noopener noreferrer">
        <IconImplant className={classes.icon} fontSize={"large"} />
      </a>
    );
  }

  return (
    <Container maxWidth={"md"} className={classes.container}>
      <Typography className={classes.textStyle}>
        Tel: +90 0539 850 1787
      </Typography>
      <Box
        className={classes.externalLinks}
        display="flex"
        justifyContent="center"
        width={30}
        m="auto"
      >
        {/*<IconHolder*/}
        {/*  customLink="https://twitter.com/"*/}
        {/*  IconImplant={TwitterIcon}*/}
        {/*/>*/}
        {/*<IconHolder*/}
        {/*  customLink="https://facebook.com/"*/}
        {/*  IconImplant={FacebookIcon}*/}
        {/*/>*/}
        <IconHolder
          customLink="https://linkedin.com/"
          IconImplant={LinkedInIcon}
        />
      </Box>
      <Divider />
      <Typography className={classes.textStyle}>
        {"© "}
        {new Date().getFullYear()} Av. Nuray Kaya Gürpınar,
        <em style={{ whiteSpace: "pre" }}> LL.M. </em>
      </Typography>
    </Container>
  );
}

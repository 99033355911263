import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import GidoleRegularTtf from "./assets/fonts/Gidole-Regular.ttf";
import GidoleRegularWoff from "./assets/fonts/Gidole-Regular.woff";
import GidoleRegularWoff2 from "./assets/fonts/Gidole-Regular.woff2";
import ButlerStencilMediumWoff from "./assets/fonts/ButlerStencil-Medium.woff";
import ButlerStencilMediumWoff2 from "./assets/fonts/ButlerStencil-Medium.woff2";

const gidoleRegular = {
  fontFamily: "Gidole",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('Gidole'),
    local('Gidole-Regular'),
    url(${GidoleRegularTtf}),
    url(${GidoleRegularWoff2}) format('woff2'),
    url(${GidoleRegularWoff}) format('woff')
  `,
};

const butlerStencilMedium = {
  fontFamily: "Butler",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('Butler'),
    local('ButlerStencil-Medium'),
    url(${ButlerStencilMediumWoff2}) format('woff2'),
    url(${ButlerStencilMediumWoff}) format('woff')
  `,
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    selected: "#262461",
    eggWhite: "#f0ead6",
    tertiary: "#afb079",
    green: "#657932",
    writing: "#798777",
  },
  typography: {
    fontFamily: [
      "Gidole",
      "Butler",
      "Times New Roman",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
    ].join(","),
    subtitle2: {
      fontFamily: "Gidole",
      fontWeight: 300,
      fontSize: 22,
    },
    subtitle1: {
      fontFamily: "Gidole",
      fontWeight: 200,
      fontSize: 15,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [butlerStencilMedium, gidoleRegular],
      },
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1250, // previous value: 1100 - mert
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  // containerPartners: {
  //
  // }
});

export default theme;

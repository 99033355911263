import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"; // Load worker code separately with worker-loader

mapboxgl.workerClass = MapboxWorker;

mapboxgl.accessToken =
  "pk.eyJ1Ijoib21lcmNhbmRhbiIsImEiOiJja2h4bm96NXkyOGp0MnlwYnNza3F4bXB4In0.cMjR0ZUu9tnlF7VZ7iUNmA";

const officeCoords = {
  lon: 27.29322010173487,
  lat: 37.10523972121226,
};

const zoom = 14;

export default function MapComponent() {
  const mapContainerRef = useRef(null);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [officeCoords.lon, officeCoords.lat],
      zoom: zoom,
    });

    let marker = new mapboxgl.Marker({ color: "#FF0000" }).setLngLat([
      officeCoords.lon,
      officeCoords.lat,
    ]);

    marker.addTo(map);

    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={mapContainerRef}
      style={{
        marginTop: 50,
        height: "50vh",
        width: "100%",
      }}
    />
  );
}

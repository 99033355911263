import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      test: "Hello",
      map_title: "Harita üzerinde konumumuz",
      map: "Map",
      home: "Home",
      areas: "Practice Areas",
      articles: "Articles",
      testimonials: "Testimonials",
      contact: "Contact",
      contact_us: "Contact",
      fab_text: "Scroll Up",
      areasSection_intro_title: ["PRACTICE AREAS"],
      areasSection_intro_subtitle: "Sizi Temsil Edelim",
      servicesSection_intro_title: ["ARTICLES"],
      servicesSection_intro_subtitle: "Stratejik Ortağınız",
      practice_areas: [
        "INSURANCE LAW",
        "MARITIME LAW",
        "LOGISTICS AND TRANSPORT LAW",
        "CONSUMER LAW",
        "START-UP LAW",
        "REAL ESTATE AND PROPERTY LAW",
        "LAW OF CONTRACTS",
        // "CORPORATE LAW",
      ],
      corporate_law_text:
        "NKG HUKUK BÜROSU bünyesindeki avukatlar, İstinye bölgesindeki müvekkillerimizin Şirketler Hukuku konusu sorularına yanıt vermeye hazırdır. Her türde ve büyüklükte müvekkile yardımcı olacak hukuki deneyimlerimizi hizmetinize sunuyoruz. Hemen bizi arayın.",
      tax_law: "TAX LAW",
      tax_law_text:
        "Uzun yıllardır Vergi Kanunu alanında çalışıyor ve sayısız başarıya imza atıyoruz. İstinye bölgesindeki müvekkillerimiz, Vergi Kanunu konusunda uzman ekibimizin yasal süreci uygun sürede ve etkin bir şekilde yöneterek başarı sağlayacağından emin olabilir.",
      accident_law: "ACCIDENT LAW",
      accident_law_text:
        "Ferdi Kaza Hukuku konusundaki çeşitli davalarda danışmanlık sunan NKG HUKUK BÜROSU, İstinye bölgesinin en yeniliki ve detaylı davalarından bazılarında yer alıyor. Size nasıl yardım edebileceğimizi görmek için hemen bizi arayın.",
      resume:
        "Nuray Kaya Gürpınar was born in 1987 in Stuttgart, Germany. After graduating from Istanbul Bilgi University Faculty of Law with honors in 2012, she completed her legal internship and obtained her attorney’s license in 2013. She worked for one of the most elite law firms based in Istanbul between 2012 and 2020, during which she gained a wide range of experience particularly in insurance law, maritime law, and logistics & transport law while making successful contributions to challenging files. She received her master's degree (LL.M.) in Human Rights Law at Istanbul Bilgi University in 2019, while she was working in the field of private law. In 2020, she settled in Bodrum and established her own law office. Nuray Kaya Gürpınar continues to serve her clients in the fields of insurance law, maritime law, and logistics & transport law. She also provides legal services in key sectors including consumer law, start-up law, and real estate & property law. Nuray Kaya Gürpınar is fluent in English and proficient in German. She is married with two children.",
      parallaxTitle: ["Att. Nuray Kaya Gürpınar,", <em> LL.M. </em>],
    },
  },
  tr: {
    translation: {
      test: "Merhaba",
      map_title: "Our location on map",
      map: "Harita",
      home: "Ana Sayfa",
      areas: "Çalışma Alanları",
      articles: "Makaleler",
      testimonials: "Müvekkil Görüşleri",
      contact: "İletişim",
      contact_us: "İLETİŞİM",
      fab_text: "Yukarı Çık",
      areasSection_intro_title: ["ÇALIŞMA ALANLARI"],
      areasSection_intro_subtitle: "Sizi Temsil Edelim",
      servicesSection_intro_title: ["MAKALELER"],
      servicesSection_intro_subtitle: "Stratejik Ortağınız",
      practice_areas: [
        "SİGORTA HUKUKU",
        "DENİZ TİCARET HUKUKU",
        "LOJİSTİK VE TAŞIMA HUKUKU",
        "TÜKETİCİ HUKUKU",
        "GİRİŞİMCİLİK (START-UP) HUKUKU",
        "GAYRİMENKUL HUKUKU",
        "SÖZLEŞMELER HUKUKU",
        // "ŞİRKETLER HUKUKU",
      ],
      corporate_law_text:
        "NKG HUKUK BÜROSU bünyesindeki avukatlar, İstinye bölgesindeki müvekkillerimizin Şirketler Hukuku konusu sorularına yanıt vermeye hazırdır. Her türde ve büyüklükte müvekkile yardımcı olacak hukuki deneyimlerimizi hizmetinize sunuyoruz. Hemen bizi arayın.",
      tax_law: "VERGİ KANUNU",
      tax_law_text:
        "Uzun yıllardır Vergi Kanunu alanında çalışıyor ve sayısız başarıya imza atıyoruz. İstinye bölgesindeki müvekkillerimiz, Vergi Kanunu konusunda uzman ekibimizin yasal süreci uygun sürede ve etkin bir şekilde yöneterek başarı sağlayacağından emin olabilir.",
      accident_law: "FERDİ KAZA HUKUKU",
      accident_law_text:
        "Ferdi Kaza Hukuku konusundaki çeşitli davalarda danışmanlık sunan NKG HUKUK BÜROSU, İstinye bölgesinin en yeniliki ve detaylı davalarından bazılarında yer alıyor. Size nasıl yardım edebileceğimizi görmek için hemen bizi arayın.",
      resume:
        "Nuray Kaya Gürpınar 1987 yılında Stuttgart, Almanya’da doğdu. İstanbul Bilgi Üniversitesi Hukuk Fakültesi’nden 2012 yılında onur derecesi ile mezun olduktan sonra 2013 yılında avukatlık stajını tamamlayarak avukatlık ruhsatını aldı. 2012 – 2020 yılları arasında, özellikle sigorta hukuku, deniz ticaret hukuku, lojistik ve taşıma hukuku alanlarında uzmanlaşmış, tanınan, İstanbul’daki bir hukuk bürosunda önemli deneyimler kazandı ve zorlu dosyalara başarılı katkılar sundu. Özel hukuk alanında çalışmaya devam ettiği sırada, 2019 yılında, İstanbul Bilgi Üniversitesi’nden İnsan Hakları Hukuku yüksek lisans (LL.M.) derecesini aldı. 2020 yılında Bodrum’a yerleşip kendi avukatlık bürosunu kuran Av. Nuray Kaya Gürpınar, halihazırda sigorta hukuku, deniz ticaret hukuku, lojistik ve taşıma hukuku alanlarında müvekkillerine hizmet verirken deneyim yelpazesine tüketici hukuku, girişimcilik (start-up) hukuku, gayrimenkul hukuku alanlarını da dahil ederek yoluna devam ediyor. Çok iyi derecede İngilizce ve iyi derecede Almanca bilen Nuray Kaya Gürpınar, evli ve iki çocuk annesidir.",
      parallaxTitle: ["Av. Nuray Kaya Gürpınar,", <em> LL.M. </em>],
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "tr",

    keySeparator: false, // we do not use keys in form messages.welcome
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

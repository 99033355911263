import Grid from "@material-ui/core/Grid";
import React from "react";
import { Parallax } from "react-parallax";
import lawyerScaleImageLG from "../assets/images/photoLG.jpg";
import lawyerScaleImageMD from "../assets/images/photoMD.jpg";
import lawyerScaleImageXS from "../assets/images/photoXS.jpg";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logoImage from "../assets/images/logo.png";
import { Fade } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f6f6f6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  gridText: {
    display: "flex",
    alignItems: "center",
    // marginLeft: 60,
    // marginRight: 60,
    // marginTop: 95,
    // marginBottom: 40,
    // [theme.breakpoints.down("lg")]: {
    //   marginLeft: 44,
    //   marginRight: 44,
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginTop: 40,
    // },
    // [theme.breakpoints.only("xs")]: {
    //   marginTop: 40,
    //   paddingBottom: 50,
    //   marginRight: 25,
    //   marginLeft: 25,
    // },
  },
  headLine: {
    color: theme.palette.green,
    display: "inline-block",

    fontSize: "2.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "2.7rem",
      marginBottom: "0.6vh",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: `calc(1.5em + 0.3vw)`,
      marginBottom: "0.6vh",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "4.5rem",
    },
  },
  // boxSubtitle: {
  //   [theme.breakpoints.only("sm")]: {
  //     fontSize: "1.4rem",
  //   },
  //   "@media (max-width:580px)": {
  //     fontSize: "1.4rem",
  //     color: theme.palette.green,
  //     paddingTop: 20,
  //     paddingBottom: 20,
  //   },
  // },
  boxParagraph: {
    alignContent: "center",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      fontSize: `calc(1em + 0.5vw)`,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: `calc(0.90em + 0.5vw)`,
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: `calc(0.80rem + 0.5vw)`,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: `calc(0.80rem + 0.5vw)`,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: `calc(0.85em + 0.4vw)`,
    },
  },
  mainTextBox: {
    padding: "3rem",
    [theme.breakpoints.up("lg")]: {
      // height: 800,
      position: "relative",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "auto",
      position: "relative",
    },
    [theme.breakpoints.only("xs")]: {
      height: "auto",
      position: "relative",
    },
  },
  logoOverlay: {
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    opacity: 0.1,
    width: "80%",
    height: "100%",
    // [theme.breakpoints.only("xl")]: {
    //   width: `45vw`,
    //   height: "90%",
    // },
    // [theme.breakpoints.down("sm")]: { width: `calc(100% - 2rem)` },
    // [theme.breakpoints.only("lg")]: { height: "95%" },
    // width: `calc(100% + 1rem)`,
    // height: "80%",
    position: "absolute",
  },
  parallaxDiv: {
    [theme.breakpoints.only("lg")]: {
      height: 900,
    },
    [theme.breakpoints.only("xl")]: {
      height: 900,
    },
    [theme.breakpoints.only("md")]: {
      height: 800,
    },
    [theme.breakpoints.only("sm")]: {
      height: 850,
    },
  },
}));

function ParallaxSection({ parallaxTitle, width }) {
  const classes = useStyles();

  // const isMiddle = window.innerWidth < 1100;
  // const lawyerScaleImage = isMiddle ? lawyerScaleImageMD : lawyerScaleImageLG;

  let lawyerScaleImage;
  if (width === "xl" || width === "lg") {
    lawyerScaleImage = lawyerScaleImageLG;
  } else if (width === "md" || width === "sm") {
    lawyerScaleImage = lawyerScaleImageMD;
  }

  const { t } = useTranslation();
  return (
    <Grid container component="main" className={classes.container}>
      <Hidden xsDown>
        <Fade in={true} timeout={1500}>
          <Grid item sm={6} md={6}>
            <img
              src={lawyerScaleImage}
              width={"100%"}
              alt={"Nuray Kaya Gurpinar"}
              style={{maxHeight: 750, objectFit: "contain", alignSelf: "center"}}
            />
            {/*<Parallax*/}
            {/*  bgImage={lawyerScaleImage}*/}
            {/*  strength={600}*/}
            {/*  bgImageStyle={{*/}
            {/*    width: "100%",*/}
            {/*    height: 750,*/}
            {/*    objectFit: "contain",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div className={classes.parallaxDiv} />*/}
            {/*</Parallax>*/}
          </Grid>
        </Fade>
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12}>
          <img
            src={lawyerScaleImageXS}
            width={"100%"}
            alt={"Nuray Kaya Gurpinar"}
          />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={6} md={6}>
        <Typography component="div" className={classes.mainTextBox}>
          <div className={classes.logoOverlay} />
          {parallaxTitle && (
            <Hidden xsDown>
              <Box
                className={[classes.gridText, classes.headLine].join(" ")}
                fontWeight="fontWeightLight"
                m={1}
                fontSize="h1.fontSize"
                letterSpacing={6}
              >
                {parallaxTitle.map((text) => (
                  <React.Fragment key={text}>{text}</React.Fragment>
                ))}
              </Box>
            </Hidden>
          )}
          {/*<Box*/}
          {/*  className={[classes.gridText, classes.boxSubtitle].join(" ")}*/}
          {/*  fontSize="h4.fontSize"*/}
          {/*  m={1}*/}
          {/*  color="#63686e"*/}
          {/*>*/}
          {/*  KARARLI, DİKKATLİ, DAKİK.*/}
          {/*</Box>*/}
          <Box
            className={`${classes.gridText} ${classes.boxParagraph}`}
            // fontSize="h6.fontSize"
            m={1}
            color="#63686e"
            align={"justify"}
          >
            {t("resume")}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withWidth()(ParallaxSection);

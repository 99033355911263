import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function LanguageChangerButton({ className }) {
  const [lang, setLang] = useState("tr");
  const [initialized, setInitialized] = useState(false);
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (lng) => {
      setLang(lng);
      localStorage.setItem("language", lng);
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  useEffect(() => {
    if (!initialized) {
      changeLanguage(localStorage.getItem("language") || "tr");
      setInitialized(true);
    }
  }, [initialized, changeLanguage]);

  return (
    <Button
      className={className}
      variant="text"
      disableRipple={true}
      disableFocusRipple={true}
      onClick={() => {
        if (lang === "en") {
          changeLanguage("tr");
        } else if (lang === "tr") {
          changeLanguage("en");
        }
      }}
    >
      {lang === "en" && "TR"}
      {lang === "tr" && "EN"}
    </Button>
  );
}

import React from "react";
import ParallaxSection from "./ParallaxSection";
import IntroToSection from "./IntroToSection";
import TwoBoxViewComponent from "./TwoBoxViewComponent";
import { useTranslation } from "react-i18next";
import { Hidden, Box } from "@material-ui/core";

export default function MiddleSection() {
  const { t } = useTranslation();

  const XsBuffer = ({ height }) => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Hidden smUp>
          <Box
            style={{
              height: { height },
              width: "100%",
              backgroundColor: "red",
            }}
          />
        </Hidden>
      </div>
    );
  };

  return (
    <>
      <ParallaxSection />
      <div id="areas">
        <IntroToSection
          title={t("areasSection_intro_title")}
          // subtitle={t("areasSection_intro_subtitle")}
        />
      </div>
      <XsBuffer height={100} />
      <div style={{ marginTop: 100, marginBottom: 100 }}>
        <TwoBoxViewComponent
          titles={t("practice_areas")}
          // detail={t("corporate_law_text")}
        />
      </div>
      <div id="articles" style={{ marginTop: 100, marginBottom: 100 }}>
        <IntroToSection title={t("servicesSection_intro_title")} />
      </div>
    </>
  );
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import insuranceLaw from "../assets/images/insurance_law.svg";
import maritimeLaw from "../assets/images/maritime_law.svg";
import transportLaw from "../assets/images/transport_law.svg";
import consumerLaw from "../assets/images/consumer_law.svg";
import startupLaw from "../assets/images/startup_law.svg";
import realEstateLaw from "../assets/images/realEstate_law.svg";
import contractLaw from "../assets/images/contract_law.svg";
// import corporateLaw from "../assets/images/corporate_law.svg";
import { Fade } from "@material-ui/core";
import { InView } from "react-intersection-observer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 150,
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.only("xs")]: {
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "70% 70%",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundColor: "#f6f6f6",
  },
  startupImage: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "55% 55%",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundColor: "#f6f6f6",
  },
  paper: {
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(4, 10, 4),
    },
    [theme.breakpoints.only("md")]: {
      margin: theme.spacing(4, 5, 4),
    },
    [theme.breakpoints.only("sm")]: {
      margin: theme.spacing(2, 5, 2),
    },
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(2, 5, 2),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
  },
  addressContainer: {
    background: theme.palette.green,
    display: "flex",
    justifyContent: "center",
  },
  addressHeadline: {
    color: "whitesmoke",
    marginTop: `calc(0.75em + 0.5vw)`,
    [theme.breakpoints.only("xl")]: {
      fontSize: `calc(0.65rem + 1vw)`,
      textAlign: "center",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: `calc(0.65rem + 1vw)`,
      textAlign: "center",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: `calc(0.75rem + 1vw)`,
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: `calc(0.45rem + 1vh)`,
      marginTop: `calc(0.5rem + 3vh)`,
      textAlign: "center",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: `calc(0.85rem + 0.3vw)`,
      textAlign: "center",
    },
  },
  addressLine: {
    color: theme.palette.eggWhite,
    [theme.breakpoints.only("md")]: {
      fontSize: `calc(0.8rem + 0.2vw)`,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: `calc(0.6rem + 0.3vw)`,
      marginTop: `calc(1rem + 2vh)`,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: `calc(0.7rem + 0.3vw)`,
    },
  },
}));

const images = [
  {
    url: insuranceLaw,
    alt: "insurance law by Vectors Point from the Noun Project",
  },
  {
    url: maritimeLaw,
    alt: "admiralty law by Vectors Point from the Noun Project",
  },
  {
    url: transportLaw,
    alt: "transport by Naveen from the Noun Project",
  },
  {
    url: consumerLaw,
    alt: "Consumer Protection by HeadsOfBirds from the Noun Project",
  },
  {
    url: startupLaw,
    alt: "startup by Alfredo @ IconsAlfredo.com from the Noun Project",
  },
  {
    url: realEstateLaw,
    alt: "real estate law by myiconfinder from the Noun Project",
  },
  {
    url: contractLaw,
    alt: "agreement by Maxicons from the Noun Project",
  },
  // {
  //   url: corporateLaw,
  //   alt: "Business law by Vectors Point from the Noun Project",
  // },
];

export default function TwoBoxViewComponent({ titles }) {
  const classes = useStyles();

  return titles.map((text, idx) => {
    return (
      <InView threshold={0.5} triggerOnce key={text}>
        {({ inView, ref }) => (
          <Fade in={inView} timeout={0} ref={ref}>
            <Grid
              container
              component="main"
              className={classes.root}
              key={text}
              ref={ref}
            >
              <Grid
                className={classes.addressContainer}
                item
                xs={12}
                sm={6}
                md={6}
              >
                <Typography
                  component="div"
                  className={classes.paper}
                  align={"justify"}
                >
                  <Box
                    variant="h4"
                    className={classes.addressHeadline}
                    m={1}
                    letterSpacing={1}
                    fontWeight="fontWeightLight"
                    fontSize="h4.fontSize"
                  >
                    {text}
                  </Box>
                  <br />
                  {/*<Box*/}
                  {/*  className={classes.addressLine}*/}
                  {/*  variant="subtitle1"*/}
                  {/*  color="#63686e"*/}
                  {/*  letterSpacing={1}*/}
                  {/*  m={1}*/}
                  {/*  fontWeight="fontWeightLight"*/}
                  {/*  fontSize="h6.fontSize"*/}
                  {/*  lineHeight={1.6}*/}
                  {/*>*/}
                  {/*  {detail}*/}
                  {/*</Box>*/}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={idx !== 4 ? classes.image : classes.startupImage}
                title={images[idx].alt}
                style={{
                  backgroundImage: `url(${images[idx].url})`,
                }}
              >
                <Box style={{ height: 150 }} />
              </Grid>
            </Grid>
          </Fade>
        )}
      </InView>
    );
  });
}

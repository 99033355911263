import React from "react";
import Header from "../components/Header";
import MiddleSection from "../components/MiddleSection";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Hidden } from "@material-ui/core";
import AddressSection from "../components/AddressSection";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "5vw",
    paddingRight: "5vw",
    color: theme.palette.writing,
  },
}));

export default function Homepage() {
  const classes = useStyles();

  return (
    <Container
      className={classes.container}
      maxWidth={"xl"}
      id="outer-container"
    >
      <Hidden smUp>
        <MobileMenu />
      </Hidden>
      <div id="page-wrap">
        <Header />
        <MiddleSection />
        <AddressSection />
        <Footer />
      </div>
    </Container>
  );
}

import React from "react";
import { Typography, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: "11vh",
      marginBottom: "10vh",
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: -100,
      textAlign: "center",
    },
  },
  headLine: {
    color: theme.palette.green,
    [theme.breakpoints.only("xs")]: {
      fontSize: "2rem",
      marginBottom: "1.5rem",
    },
  },
  subTitle: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "5vh",
      marginBottom: "5vh",
    },
    textAlign: "center",
  },
}));

export default function IntroToSection({ title, subtitle }) {
  const classes = useStyles();
  return (
    <Grid container component="main" style={{ height: 400 }}>
      <Grid item xs={12} sm={12} className={classes.container}>
        <Typography component="div">
          <Box
            fontWeight="fontWeightLight"
            m={1}
            fontSize="h3.fontSize"
            className={classes.headLine}
            letterSpacing={3}
          >
            {title.map((text) => (
              <React.Fragment key={text}>{text}</React.Fragment>
            ))}
          </Box>
          {subtitle && (
            <Box
              m={1}
              fontSize="subtitle2.fontSize"
              className={classes.subTitle}
            >
              {subtitle}
            </Box>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}

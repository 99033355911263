import React from "react";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: "1vw",
    right: "5vw",
    zIndex: 1500,
  },
  fabGreen: {
    color: theme.palette.common.white,
    //alternate backgroundColor: "#798777",
    backgroundColor: theme.palette.green,
    "&:hover": {
      backgroundColor: "#464f41",
    },
  },
}));

export default function FabButton() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Hidden xsDown>
        <Fab variant="extended" className={clsx(classes.fab, classes.fabGreen)}>
          <NavigationIcon />
          {t("fab_text")}
        </Fab>
      </Hidden>
      <Hidden smUp>
        <Fab variant="extended" className={clsx(classes.fab, classes.fabGreen)}>
          <NavigationIcon />
        </Fab>
      </Hidden>
    </>
  );
}

import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import addressImage from "../assets/images/bodrum.jpeg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${addressImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  addressContainer: {
    background: theme.palette.green,
  },
  addressHeadline: {
    fontSize: `calc(2em + 1vw)`,
    color: "whitesmoke",
  },
  addressLine: {
    fontSize: `calc(1em + 1vw)`,
    color: theme.palette.eggWhite,
  },
}));

export default function SplitContent() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div id={"contact"}>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={6} md={6} className={classes.image} />
        <Grid
          className={classes.addressContainer}
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={1}
          square
        >
          <div className={classes.paper}>
            <Typography variant="h4" noWrap className={classes.addressHeadline}>
              {t("contact")}
            </Typography>
            <br />
            <Typography className={classes.addressLine} variant="h5" noWrap>
              Yalıkavak Mah. Eser Cad. No: 2/22
            </Typography>
            <Typography variant="h5" className={classes.addressLine}>
              Bodrum, Muğla
            </Typography>
            <br />
            <Typography variant="h5" className={classes.addressLine}>
              nuray@av-nkg.com
            </Typography>
            <br />
            <Typography variant="h5" className={classes.addressLine}>
              Tel: +90 0539 850 17 87
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
